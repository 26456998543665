import {post} from '@/utils/axios'

// 获取课外读物推荐列表图片
export const getReadingList = (params) => post('/shida-user-biz/TbBookRecommend/getDataOn', params) 

// 获取中学生影视推荐
export const getVideoList = (params) => post('/shida-user-biz/TbVideoRecommend/getDataOn', params) 

// 获取心理知识共享列表
export const getPsyList = (params) => post('/shida-user-biz/TbPsyShare/getDataOn', params)

// 心理知识共享增加阅读量
export const psyAddReadNum = (params) => post('/shida-user-biz/TbPsyShare/updateReadNum', params)
